import { default as indexQ6aaLm6uHyMeta } from "/app/pages/contacts/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexqaTJgriYEwMeta } from "/app/pages/manufacturing/index.vue?macro=true";
import { default as _91_46_46_46slug_93Cy8OQ3llwUMeta } from "/app/pages/news/[...slug].vue?macro=true";
import { default as indexoxV4GRc61hMeta } from "/app/pages/news/index.vue?macro=true";
import { default as indexdd0y1GP2UcMeta } from "/app/pages/projects/index.vue?macro=true";
export default [
  {
    name: indexQ6aaLm6uHyMeta?.name ?? "contacts",
    path: indexQ6aaLm6uHyMeta?.path ?? "/contacts",
    meta: indexQ6aaLm6uHyMeta || {},
    alias: indexQ6aaLm6uHyMeta?.alias || [],
    redirect: indexQ6aaLm6uHyMeta?.redirect,
    component: () => import("/app/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexqaTJgriYEwMeta?.name ?? "manufacturing",
    path: indexqaTJgriYEwMeta?.path ?? "/manufacturing",
    meta: indexqaTJgriYEwMeta || {},
    alias: indexqaTJgriYEwMeta?.alias || [],
    redirect: indexqaTJgriYEwMeta?.redirect,
    component: () => import("/app/pages/manufacturing/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Cy8OQ3llwUMeta?.name ?? "news-slug",
    path: _91_46_46_46slug_93Cy8OQ3llwUMeta?.path ?? "/news/:slug(.*)*",
    meta: _91_46_46_46slug_93Cy8OQ3llwUMeta || {},
    alias: _91_46_46_46slug_93Cy8OQ3llwUMeta?.alias || [],
    redirect: _91_46_46_46slug_93Cy8OQ3llwUMeta?.redirect,
    component: () => import("/app/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexoxV4GRc61hMeta?.name ?? "news",
    path: indexoxV4GRc61hMeta?.path ?? "/news",
    meta: indexoxV4GRc61hMeta || {},
    alias: indexoxV4GRc61hMeta?.alias || [],
    redirect: indexoxV4GRc61hMeta?.redirect,
    component: () => import("/app/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexdd0y1GP2UcMeta?.name ?? "projects",
    path: indexdd0y1GP2UcMeta?.path ?? "/projects",
    meta: indexdd0y1GP2UcMeta || {},
    alias: indexdd0y1GP2UcMeta?.alias || [],
    redirect: indexdd0y1GP2UcMeta?.redirect,
    component: () => import("/app/pages/projects/index.vue").then(m => m.default || m)
  }
]