<template>
  <footer class="footer dark-bg">
    <div class="container">
      <div class="footer__wrapper">
        <div class="company-info">
          <main-logo />
          <p class="footer__text">
            <span>ООО "СМАРТЛИНК"</span>
            <span>ИНН 7813654235</span>
          </p>
        </div>
        <div class="footer__column">
          <p class="footer__column-title">TERPO SOFTWARE</p>
          <nav class="footer__nav">
            <nuxt-link to="/" class="footer__link" @click="onIndexPageClick"> Главная </nuxt-link>
            <nuxt-link :to="{ path: '/', hash: '#services' }" class="footer__link"> Услуги </nuxt-link>
            <nuxt-link to="/projects" class="footer__link"> Проекты </nuxt-link>
            <nuxt-link to="/news" class="footer__link"> Новости </nuxt-link>
            <nuxt-link to="/contacts" class="footer__link"> Контакты </nuxt-link>
          </nav>
        </div>
        <div class="footer__column">
          <p class="footer__column-title">КОНТАКТЫ</p>
          <div class="footer__nav">
            <nuxt-link href="tel:+78122093374" class="footer__link"> +7 (812) 209-33-74 </nuxt-link>
            <nuxt-link href="mailto:info@terpo.ru" class="footer__link"> info@terpo.ru </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import mainLogo from '~/components/icons/main-logo.vue';
const onIndexPageClick = () => {
  window.scrollTo(0, 0);
};
</script>

<style scoped lang="scss">
.footer {
  padding: 40px 0 74px;
  color: var(--subtitle-color);
  font-size: var(--standard-text);
  line-height: var(--standard-line);

  &__wrapper {
    display: flex;
    gap: 136px;
  }

  .company-info {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__link {
    font-size: 20px;
    line-height: 32px;
    color: var(--accent-color);
  }

  &__column-title {
    text-transform: uppercase;
    margin-bottom: 23px;
  }
}

@media (max-width: 767px) {
  .footer {
    &__wrapper {
      flex-direction: column;
      gap: 40px;
      align-items: center;
    }

    &__column,
    &__nav {
      align-items: center;
      text-align: center;
    }

    .company-info {
      align-items: center;
    }
  }
}
</style>
