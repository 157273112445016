<template>
  <div class="overlay">
    <main-layout>
      <NuxtPage />
    </main-layout>
  </div>
</template>

<script setup lang="ts">
import MainLayout from "~/components/layout/main-layout.vue";
import { useRoute } from "vue-router";

const route = useRoute();

useHead({
  title: () => (route.meta.title ? `${route.meta.title}` : ""),
  meta: [
    {
      name: "description",
      content: () =>
        route.meta.description
          ? `${route.meta.description}`
          : `${route.meta.title} | ''`,
    },
    {
      name: "keywords",
      content: route.meta.keywords ? `${route.meta.keywords}` : "",
    },
    { property: "og:locale", content: "ru_RU" },
    { property: "og:type", content: "website" },
    {
      property: "og:title",
      content: () => (route.meta.title ? `${route.meta.title}` : ""),
    },
    {
      property: "og:description",
      content: () =>
        route.meta.description
          ? `${route.meta.description}`
          : `${route.meta.title} | ''`,
    },
  ],
  link: [
    {
      rel: "canonical",
      href: "https://terpo.ru" + route.path,
    },
  ],
});
</script>
